<template>
  <div class="m3-flex-col m3-align-items-start m3-padding-horizontal-l">
    <SearchForm :is-admin-user="isAdminUser"
        @change="searchChange" @search="toSearch" @addKeyword="edit(null)"/>

    <a-table class="m3-width-full"
             :dataSource="tableList" :columns="tableColumns"
             :scroll="{ x: 1400 }"
             :loading="listLoading" size="small"
             :pagination="pagination">
      <!--        <template #company="{record}">-->
      <!--          <div class="m3-line-clamp-1">{{ record.company }}</div>-->
      <!--          &lt;!&ndash;          <div class="m3-line-clamp-1 m3-margin-top-s">{{ record.ip }}</div>&ndash;&gt;-->
      <!--        </template>-->
      <template #keywords="{record}">
        <div class="m3-line-clamp-3">{{ record.keywords }}</div>
      </template>
      <template #status="{record}">
        <span :class="rowStatusClass(record)"
              class="m3-padding-horizontal-s m3-padding-vertical-xs m3-text-center md-label-small">
          {{ rowStatusDesc(record) }}
        </span>
      </template>
      <template #risk="{record}">
        <span :class="rowRiskClass(record)"
              class="m3-padding-horizontal-s m3-padding-vertical-xs m3-text-center md-label-small">
          {{ rowRiskDesc(record) }}
        </span>
      </template>
      <template #updateTime="{record}">
        <div class="m3-line-clamp-1">
          <div>创建：{{ record.createTime }}</div>
          <div class="m3-margin-top-xs">更新：{{ record.updateTime }}</div>
        </div>
      </template>
      <template #handle="{record}">
        <a-button size="small" style="margin-right: 8px" type="primary" @click="edit(record)">编辑</a-button>
        <a-popconfirm
            title="确定删除此条数据?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteConfirm(record)"
        >
          <a-button size="small" type="primary" danger>删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <EditKeywordDialog v-model:dialog-visible="editDialogVisible"
                       :record="currentRecord"
                       @confirm="editDialogConfirm"/>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs, watch} from "vue";
import SearchForm from "@/views/open/apiQuality/keyword/SearchForm.vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import {message} from "ant-design-vue";
import EditKeywordDialog from "@/views/open/apiQuality/keyword/EditKeywordDialog.vue";

export default {
  name: "KeywordList",
  components: {EditKeywordDialog, SearchForm},
  props: {
    isAdminUser: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    let {isAdminUser} = toRefs(props);

    let locale = zhCN;
    let editDialogVisible = ref(false);
    let currentRecord = ref({});

    let list = ref([]);
    let listLoading = ref(false);
    let tableList = computed(() => {
      return list.value?.map(item => {
        return item;
      })
    })

    let tableColumns = ref([
      {
        title: '类型',
        key: 'name',
        dataIndex: 'name',
        width: '120px',
        // slots: {customRender: 'name'},
      },
      {
        title: '关键字',
        key: 'keywords',
        dataIndex: 'keywords',
        slots: {customRender: 'keywords'},
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: '80px',
        slots: {customRender: 'status'},
      },
      {
        title: '风险等级',
        dataIndex: 'risk',
        key: 'risk',
        width: '80px',
        slots: {customRender: 'risk'},
      },
      {
        title: '创建/更新',
        dataIndex: 'updateTime',
        key: 'updateTime',
        width: '200px',
        slots: {customRender: 'updateTime'},
      },
    ]);
    let handleColumn = {
      title: '操作',
      dataIndex: 'handle',
      key: 'handle',
      width: '200px',
      fixed: 'right',
      slots: {customRender: 'handle'},
    };
    watch(isAdminUser, (newV, preV) => {
      //管理员不展示操作栏
      let findIndex = tableColumns.value?.findIndex(item => item.title == handleColumn.title);
      if (!newV) {
        if (findIndex === -1) {
          tableColumns.value.splice(tableColumns.value.length, 0, handleColumn);
        }
      } else {
        if (findIndex !== -1) {
          tableColumns.value.splice(findIndex, 1);
        }
      }
    }, {immediate: true});

    let pagination = reactive({
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["10", "20", "30", "40"],
      // 显示总数
      showTotal: (total) => `共 ${total} 条`,
      // 改变每页数量时更新显示
      onShowSizeChange: (current, pageSize) => {
        // console.log('onShowSizeChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getList();
      },
      // 改变页数，跳转页数
      onChange: (current, pageSize) => {
        // console.log('onChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getList();
      },
    });
    let searchForm = {};

    let searchChange = async (search) => {
      if (EmptyUtil.isEmpty(searchForm)) {
        await toSearch(search);
      }
    }
    let toSearch = async (search) => {
      searchForm = search;
      await getList();
    }

    let getTranslatedContentHtml = (record) => {
      let keywords = record.hitTypeDesc
      if (EmptyUtil.isEmpty(record.translatedContent)) {
        return ['-']
      }
      let sentences = []
      try {
        sentences = JSON.parse(record.translatedContent)?.payload?.sentences
      } catch (err) {
        // 因查看对话会改变translatedContent值,这里先通过捕获异常处理
        sentences = record.translatedContent
      }
      if (keywords !== '' && keywords != null) {
        // 如果包含关键字,对关键字做高亮处理
        keywords.split(',').forEach((keyword) => {
          sentences.map((sentence) => {
            if (sentence.text.search(keyword) !== -1) {
              sentence.text = sentence.text.replace(
                  keyword,
                  '<b class="keyword-tag" style="color:#ff0000;">' +
                  keyword +
                  '</b>'
              )
              return sentence
            }
          })
        })
      }
      if (sentences == undefined || sentences.length === 0) {
        return ['-']
      }
      return sentences.map((sentence) => sentence.text)
    }

    let getList = async () => {
      // let params = toRaw(searchForm);
      let params = searchForm;
      let paginationParams = {
        page: pagination.current,
        pageSize: pagination.pageSize,
      };
      // console.log('getList', params);
      listLoading.value = true;
      let res = await safePromise(_lineService._apiService.getKeywordList({...paginationParams, ...params}))
      // res.data?.records?.forEach((item, i) => {
      //   if (item.hitTypeDesc == null) {
      //     item.hitTypeDescArr = []
      //   } else {
      //     item.hitTypeDescArr = item.hitTypeDesc.split(',')
      //   }
      //   // item.translatedContent = getTranslatedContent(item);
      //   item.translatedContentHtml = getTranslatedContentHtml(item);
      // });
      pagination.total = res?.data?.total || 0;
      list.value = res?.data?.records || [];
      listLoading.value = false;
      console.log('getList', res, res?.data?.records);
    }

    let edit = (record) => {
      currentRecord.value = record;
      editDialogVisible.value = true;
    }

    let deleteConfirm = async (record) => {
      const res = await safePromise(_lineService._apiService.deleteKeywords({id: record.id}));
      if (res?.code == 200) {
        let findIndex = list.value.findIndex(item => item.id == record.id);
        if (findIndex !== -1) {
          list.value.splice(findIndex, 1);
        }
        message.success('删除成功')
      }
    }

    let editDialogConfirm = (record) => {
      if (record?.id) {
        let findIndex = list.value.findIndex(item => item.id == record?.id);
        if (findIndex !== -1) {
          //编辑
          list.value.splice(findIndex, 1, record);
        } else {
          //新增
          list.value.splice(0, 0, record);
        }
      } else {
        // 如果没有id，则刷新列表
        getList();
      }

    }
    return {
      locale,
      searchForm, pagination,
      editDialogVisible, currentRecord, editDialogConfirm,
      searchChange, toSearch, getList, tableList, tableColumns, listLoading,
      edit, deleteConfirm,
    };
  },
  beforeCreate() {
    this.statusList = [
      {value: 0, label: '正常'},
      {value: 1, label: '禁用'},
    ]
    this.riskList = [
      {
        value: null,
        label: '全部',
      },
      {
        value: 3,
        label: '高',
        labelClass: 'risk-high-more'
      },
      {
        value: 2,
        label: '中',
        labelClass: 'risk-high'
      },
      {
        value: 1,
        label: '低',
        labelClass: 'risk-mid'
      },
      {
        value: 0,
        label: '无',
        labelClass: 'risk-low'
      },
    ];
  },
  computed: {
    rowRiskClass() {
      return row => {
        let res = '';
        let find = this.riskList.find(item => item.value == row.risk);
        res = find?.labelClass || '';
        return res;
      }
    },
    rowRiskDesc() {
      return row => {
        let res = '';
        let find = this.riskList.find(item => item.value == row.risk);
        res = find?.label || '';
        return res;
      }
    },
    rowStatusDesc() {
      return row => {
        let res = '';
        let find = this.statusList.find(item => item.value == row.status);
        res = find?.label || '';
        return res;
      }
    },
    rowStatusClass() {
      return row => {
        let res = '';
        let find = this.statusList.find(item => item.value == row.status);
        res = find?.labelClass || '';
        return res;
      }
    },
  },
}
</script>

<style lang="less" scoped>
</style>